import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import {
  default as axiosBaseURL,
  adminaxios,
  enhancementaxios,
} from "../../../axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import AddressComponent from "./AddressComponent";
import useFormValidation from "../../customhooks/FormValidation";
import {
  assignUser,
  frequency,
  leadStatus as leadStatusJson,
} from "./ConstatantData";
import { isEmpty } from "lodash";
// Sailaja imported common component Sorting on 27th March 2023
import { Sorting } from "../../common/Sorting";
import ErrorModal from "../../common/ErrorModal";
import { ReactComponent as PersonLogoIcon } from "../../../assets/images/person_logo_icon.svg";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom";
import PermanentAddressComponent from "./PermanentAddressComponent";

const AddSuperAdmin = (props, initialValues) => {
  const history = useHistory();
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [date, setDate] = useState();

  const [openedBy, setOpenedBy] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [resetStatus, setResetStatus] = useState(false);
  const [imgSrcAadh, setImgSrcAadh] = React.useState(null);
  const [addressToggle, setAddressToggle] = useState("on");
  const [isaddressShow, setAddressIsShow] = React.useState(false);
  const MAX_WIDTH = 320;
  const MAX_HEIGHT = 180;
  const MIME_TYPE = "image/jpeg";
  const QUALITY = 1;
  const [formData, setFormData] = useState({
    display_name: "",
    legal_name: "",
    org_code: "",
    org_pan: "",
    // org_domain_name: "",
    sub_domain: "",
    website: "",
    gstin: "",
    org_logo: "",
    org_email: "",
    first_name: "",
    last_name: "",
    mobile_no: "",
    alternate_mobile_no: "",
    email: "",
    // country: "",
    // state: "",
    // comm_address: "",
    // reg_address: "",
    address: {
      house_no: "",
      street: "",
      district: "",
      state: "",
      pincode: "",
      landmark: "",
      city: "",
      country: "",
      longitude: "12.022586",
      latitude: "24.568978",
    },
    permanent_address: {
      house_no: "",
      street: "",
      district: "",
      state: "",
      pincode: "",
      landmark: "",
      city: "",
      country: "",
      longitude: "12.022586",
      latitude: "24.568978",
    },
  });
  const [inputs, setInputs] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loaderSpinneer, setLoaderSpinner] = useState(false);
  // sms toggle
  const [smsToggle, setSmsToggle] = useState("on");
  const [istelShow, setTelIsShow] = React.useState(true);
  function SMSToggle() {
    setSmsToggle(smsToggle === "on" ? "off" : "on");
    setTelIsShow(!istelShow);
  }
  //Whatsapp toggle
  const [whatsappToggle, setWhatsappToggle] = useState("on");
  const [iswhatsShow, setWhatsIsShow] = React.useState(true);
  function WHATSAPPToggle() {
    setWhatsappToggle(whatsappToggle === "on" ? "off" : "on");
    setWhatsIsShow(!iswhatsShow);
  }
  // email toggle
  const [emailToggle, setEmailToggle] = useState("on");
  const [isShow, setIsshow] = React.useState(true);
  function EmailToggle() {
    setEmailToggle(emailToggle === "on" ? "off" : "on");
    setIsshow(!isShow);
  }
  //maps toggle
  const [mapsToggle, setMapsToggle] = useState("on");
  const [ismapsShow, setMapsIsShow] = React.useState(true);
  function MapsToggle() {
    setMapsToggle(mapsToggle === "on" ? "off" : "on");
    setMapsIsShow(!ismapsShow);
  }
  const storageToken = localStorage.getItem("token");
  const token = JSON.parse(storageToken);
  let ShowAreas = false;
  if (
    (token && token.user_type === "Zonal Manager") ||
    (token && token.user_type === "Staff") ||
    (token && token.user_type === "Help Desk") ||
    (token && token.user_type === "Franchise Owner")
  ) {
    ShowAreas = true;
  }

  let DisplayAreas = false;
  if (
    (token && token.user_type === "Admin") ||
    (token && token.user_type === "Super Admin") ||
    (token && token.user_type === "Branch Owner")
  ) {
    DisplayAreas = true;
  }

  // draft
  useEffect(() => {
    if (props.lead) {
      setFormData((prevState) => {
        return {
          ...prevState,
          ...props.lead,
        };
      });
    }
  }, [props.lead]);

  const handleInputChange = (event) => {
    event.persist();
    // draft
    //props.setIsDirtyFun(true);
    setResetStatus(false);
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));

    const target = event.target;
    var value = target.value;
    const name = target.name;
  //  if(name==="customer_count"){
  //   value.replace(/\D/g, "")
  //  }

    if (target.type === "checkbox") {
      if (target.checked) {
        formData.hobbies[value] = value;
      } else {
        formData.hobbies.splice(value, 1);
      }
    } else if(event.target.name != "customer_count") {
      setFormData((preState) => ({
        ...preState,
        [name]: value.charAt(0).toUpperCase() + value.slice(1),
      }));
    }else if (event.target.name === "customer_count") {
      const validcustomer_count = event.target.value.replace(/[^0-9]/g, "");
      setFormData(prevState => ({
        ...prevState,
        customer_count: validcustomer_count
      }));
    }
  };
  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value,
      },
    }));
  };
  const handlePermanentAddressInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      permanent_address: {
        ...prevState.permanent_address,
        [name]: value,
      },
    }));
  };
  const resetformmanually = () => {
    setFormData({
      display_name: "",
      legal_name: "",
      gstin: "",
      org_logo: "",
      org_email: "",
      org_phone: "",
      org_phone: "",
      first_name: "",
      last_name: "",
      mobile_no: "",
      alternate_mobile_no: "",
      email: "",

    });
    document.getElementById("resetid").click();
    document.getElementById("myForm").reset();
  };

  //form submit
  const addDetails = () => {
    setLoaderSpinner(true);
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // let submitdata = {
    //   ...formData,
    // };
    if (!formData.address.house_no){
      formData.address.house_no="N/A"
    }
    if (!formData.permanent_address.house_no){
      formData.permanent_address.house_no="N/A"
    }
    let submitData = {
      display_name: formData.display_name,
      legal_name: formData.legal_name,
      org_pan: formData?.org_pan,
      org_email: formData.org_email,
      org_phone: formData.org_phone,
      org_code: formData.org_code,
      website: formData.website,
      sub_domain: formData.sub_domain,
      customer_count: formData?.customer_count,
      sms_api: istelShow,
      email_api: isShow,
      maps_api: ismapsShow,
      whatsapp_api: iswhatsShow,
      gstin: formData.gstin,
      communication_address: formData.address,
      documents: {
        org_logo: formData.org_logo,
      },
      org_user: {
        first_name: formData.first_name,
        last_name: formData.last_name,
        register_mobile: formData.mobile_no,
        alternate_mobile: formData.alternate_mobile_no,
        email: formData.email,
      },
      registered_address:
        addressToggle === "off"
          ? formData.permanent_address
          : formData.address,
    };

    console.log(submitData, "formDatadetails");
    if (submitData?.alternate_mobile_no === "") {
      delete submitData?.alternate_mobile_no;
    }
  
    enhancementaxios
      .post("/superadmin/org/create", submitData, config)
      .then((response) => {
        setLoaderSpinner(false);
        let responsedata = { ...response.data };
        console.log(response.data, "addinglead");
        //props.onUpdate(responsedata);
        // setShowModal(true);
        // setModalMessage("Organization Created successfully");
        toast.success("Organization Created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        resetformmanually();
        const redirectURL = `${process.env.PUBLIC_URL}/app/superAdmin/${process.env.REACT_APP_API_URL_Layout_Name}`;
        history.push(redirectURL);
        // props.dataClose()
      })

      .catch((error) => {
        setLoaderSpinner(false);
        // Check if the error response from server is defined and if it has a status of 400
        // if (error.response && error.response.status === 400) {
        //   // If there is an error message from server, set it as the modal message, otherwise, set the modal message to "Something went wrong"
        //   let errorMessage = error.response.data
        //     ? error.response.data
        //     : "Something went wrong";
        //   // setModalMessage(errorMessage);
        //   // setShowModal(true);
        //   toast.error(errorMessage, {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 2000,
        //   });
        // } 
        if (error.response && error.response.data.org_code[0]) {
          toast.error(error.response && error.response.data.org_code[0], {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        else {
          // If the error is not from the server or does not have a status of 400, set the modal message to "Something went wrong"
          // setModalMessage("Something went wrong");
          // setShowModal(true);
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      });
  };

  useEffect(() => {
    if (!props.rightSidebar) {
      resetformmanually();
      setErrors({});
    }
    // draft
    setFormData(props.lead);
  }, [props.rightSidebar]);

  const submit = (e) => {
    console.log("clicked");
    e.preventDefault();
    e = e.target.name;
    if (addressToggle === "on") {
      formData.permanent_address=formData.address;
      // setFormData((preState) => {
      //   return {
      //     ...preState,

      //     permanent_address: {
      //       house_no: preState?.address?.house_no,
      //       street: preState?.address?.street,
      //       district: preState?.address?.district,
      //       // area: preState.address.area,
      //       pincode: preState?.address?.pincode,
      //       state: preState?.address?.state,
      //       landmark: preState?.address?.landmark,
      //       city: preState?.address?.city,
      //       country: preState?.address?.country,
      //       longitude: "12.022586",
      //       latitude: "24.568978",
      //     },
      //   };
      // });
    }else{
      if(formData.permanent_address === undefined){
        let permanent_address = {
          house_no: "",
          street: "",
          district: "",
          state: "",
          // pincode: "",
          landmark: "",
          city: "",
          country: "",
          longitude: "12.022586",
          latitude: "24.568978",
        }
        formData.permanent_address=permanent_address;
      }
    }
    let dataNew = { ...formData };
    const validationErrors = validate(dataNew);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    console.log("Validation Errors:", validationErrors); // Here's the console log for errors
    if (noErrors) {
      addDetails();
    }
    // else {
    //   toast.error("errors try again", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000
    //   })
    // }
  };

  function checkEmptyValue(e) {
    if (e.target.value == "") {
      e.target.classList.remove("not-empty");
    } else {
      e.target.classList.add("not-empty");
    }
  }
  const requiredFields = [
    "first_name",
    "last_name",
    "mobile_no",
    "alternate_mobile_no",
    "display_name",
    "legal_name",
    "org_code",
    "org_pan",
    "org_phone",
    "org_logo",
    //  "org_domain_name",
    "sub_domain",
    "website",
    "gstin",
    "org_email",
    "house_no",
    "email",
    "street",
    "city",
    "pincode",
    "district",
    "state",
    "country",
    "landmark",
  ];

  const { validate } = useFormValidation(requiredFields);
  const resetForm = function () {
    setResetStatus(true);
    setInputs((inputs) => {
      var obj = {};
      for (var name in inputs) {
        obj[name] = "";
      }
      return obj;
    });
    setErrors({});
  };
  const form = useRef(null);
  // const ref = useRef();

  // useEffect(() => {
  //   ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // }, []);
  // Sailaja Added Form validation styles on 1st August
  // Form Validation Starts from here

  // let dependency = formData["customer_documents"];

  // const initialRender = useRef(true);
  // useEffect(() => {
  //   if (initialRender.current) {
  //     initialRender.current = false;
  //   } else {
  //     console.log("called");
  //     checkFieldValidity("customer_documents");
  //   }
  // }, [dependency]);

  const checkFieldValidity = (fieldName, parent) => {
    console.log("called");
    const validationErrors = validate(formData);
    let vErrors = {};
    if (validationErrors[fieldName]) {
      vErrors[fieldName] = validationErrors[fieldName];
    }

    console.log(vErrors);

    const noErrors = Object.keys(vErrors).length === 0;

    if (noErrors) {
      setErrors({ ...errors, ...{ [fieldName]: "" } });
    }
    setErrors({ ...errors, ...{ [fieldName]: vErrors[fieldName] } });
  };

  function checkEmptyValue(e) {
    if (e.target.value == "") {
      e.target.classList.remove("not-empty");
    } else {
      e.target.classList.add("not-empty");
    }
  }

  //This function will be used for validation of individual fields
  const handleInputBlur = (e, fieldName, parent) => {
    checkEmptyValue(e);
    checkFieldValidity(fieldName, parent);
  };
  // Form validations ended here
  // changed validations based on defects for all fields by Marieya on 5/08/22
  // async function UploadImageaadh(e) {
  //   validationSize(e.target);

  //   const file = e.target.files[0];
  //   if (file) {
  //     setSelectedFile(file);
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }

  //   let preview = await getBase64(e.target.files[0]);
  //   console.log(preview, "file");
  //   setFormData((preState) => ({
  //     ...preState,
  //     org_logo: preview,
  //   }));

  // }
  // function validationSize(input) {
  //   const fileSize = input.files[0].size / 1024 / 1024;
  //   if (fileSize > 2) {
  //     toast.error("Please select image below 4MB", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose:1000
  //       });

  //   }
  // }
  async function UploadImageaadh(e) {
    // Validate image size, type, height, and width
    const isValid = await validateImage(e.target);
    if (!isValid) {
      return; // Exit function if validation fails
    }

    // Continue with image upload process
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }

    let preview = await getBase64(e.target.files[0]);
    setFormData((preState) => ({
      ...preState,
      org_logo: preview,
    }));
  }

  async function validateImage(input) {
    const file = input.files[0];
    const fileSize = file.size / 1024 / 1024; // in MB

    // Check file size
    if (fileSize > 4) {
      toast.error("Please select an image below 4MB", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    }

    // Check file type
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Please select a valid image file (JPEG, PNG, GIF)", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    }

    // Optional: Check image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);
    await new Promise((resolve) => {
      img.onload = () => resolve();
    });

    // const maxWidth = 600; // Maximum allowed width
    // const maxHeight = 600; // Maximum allowed height
    // if (img.width > maxWidth || img.height > maxHeight) {
    //   toast.error("Image dimensions not supported", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   return false;
    // }

    return true; // Image passes all validations
  }
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [imagePreview1, setImagePreview1] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile1(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview1(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClick1 = () => {
    fileInputRef1.current.click();
  };

  function AddressToggle() {
    setAddressToggle(addressToggle === "on" ? "off" : "on");
    setAddressIsShow(!isaddressShow);
  }
  useEffect(() => {
    if (addressToggle === "on") {
      setFormData((preState) => {
        return {
          ...preState,

          permanent_address: {
            house_no: preState?.address?.house_no,
            street: preState?.address?.street,
            district: preState?.address?.district,
            // area: preState.address.area,
            pincode: preState?.address?.pincode,
            state: preState?.address?.state,
            landmark: preState?.address?.landmark,
            city: preState?.address?.city,
            country: preState?.address?.country,
            longitude: "12.022586",
            latitude: "24.568978",
          },
        };
      });
    }
  }, []);

  return (
    <Fragment>
      <div>
        <br />
        <Container fluid={true}>
          <Grid container spacing={1} id="breadcrumb_margin">
            <Grid item md="12">
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={
                  <NavigateNextIcon
                    fontSize="small"
                    className="navigate_icon"
                  />
                }
              >
                <Link
                  to={`${process.env.PUBLIC_URL}/app/superAdmin/${process.env.REACT_APP_API_URL_Layout_Name}`}
                >
                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color=" #377DF6"
                    fontSize="14px"
                  >
                    Organizations
                  </Typography>
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="#00000 !important"
                  fontSize="14px"
                  className="last_typography"
                >
                  Add Organizations
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <br />
          <br />
          <Row className="edit-profile data_table">
            <Col sm="12">
              <Form id="myForm" onReset={resetForm} ref={form}>
                <Row style={{ marginTop: "2%" }}>
                  <div
                    id="editmoveup"
                    style={{
                      textAlign: "left",
                      marginTop: "20px",
                      fontSize: "19px",
                      fontWeight: "600",
                      fontFamily: "Open Sans",
                      paddingLeft: "20px",
                      position: "relative",
                      top: "-25px",
                    }}
                  >
                    Org Details
                  </div>
                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Display Name *</Label>
                        <Input
                          // (Start)Remove Error msg by Clicking next tab validation for First Name Field

                          onBlur={(e) => handleInputBlur(e, "display_name")}
                          name="display_name"
                          onChange={handleInputChange}
                          value={formData && formData.display_name}
                          className={`form-control digits ${
                            formData && formData.display_name ? "" : ""
                          }`}
                          // onBlur={checkEmptyValue}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <span className="errortext">{errors.display_name}</span>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Legal Name *</Label>
                        <Input
                          // (Start)Remove Error msg by Clicking next tab validation for First Name Field

                          onBlur={(e) => handleInputBlur(e, "legal_name")}
                          name="legal_name"
                          onChange={handleInputChange}
                          value={formData && formData.legal_name}
                          className={`form-control digits ${
                            formData && formData.legal_name ? "" : ""
                          }`}
                          // onBlur={checkEmptyValue}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <span className="errortext">{errors.legal_name}</span>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Code *</Label>
                        <Input
                          // (Start)Remove Error msg by Clicking next tab validation for First Name Field

                          onBlur={(e) => handleInputBlur(e, "org_code")}
                          name="org_code"
                          onChange={handleInputChange}
                          value={formData && formData.org_code}
                          className={`form-control digits ${
                            formData && formData.org_code ? "" : ""
                          }`}
                          // onBlur={checkEmptyValue}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <span className="errortext">{errors.org_code}</span>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Email ID *</Label>
                        <Input
                          // draft
                          // (Start)Remove Error msg by Clicking next tab validation for Email Field

                          onBlur={(e) => handleInputBlur(e, "org_email")}
                          className={`form-control digits ${
                            formData && formData.org_email ? "" : ""
                          }`}
                          // className={`form-control digits ${formData && formData.email ? '' : ''}`}
                          value={formData && formData.org_email?.toLowerCase()}
                          type="email"
                          name="org_email"
                          onChange={handleInputChange}
                        />
                      </div>
                      <span className="errortext">{errors.org_email}</span>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Phone No *</Label>
                        <Input
                          // draft
                          // (Start)Remove Error msg by Clicking next tab validation for Email Field

                          onBlur={(e) => handleInputBlur(e, "org_phone")}
                          className={`form-control digits ${
                            formData && formData.org_phone ? "" : ""
                          }`}
                          // className={`form-control digits ${formData && formData.email ? '' : ''}`}
                          value={formData && formData.org_phone?.toLowerCase()}
                          type="tel"
                          name="org_phone"
                          onChange={handleInputChange}
                        />
                      </div>
                      <span className="errortext">{errors.org_phone}</span>
                    </FormGroup>
                  </Col>
                  {/* <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Org Domain*</Label>
                      <Input
                        // (Start)Remove Error msg by Clicking next tab validation for First Name Field

                        onBlur={(e) => handleInputBlur(e, "org_domain_name")}
                        name="org_domain_name"
                        onChange={handleInputChange}
                        value={formData && formData.org_domain_name}
                        className={`form-control digits ${
                          formData && formData.org_domain_name ? "" : ""
                        }`}
                        // onBlur={checkEmptyValue}
                        style={{ textTransform: "capitalize" }}
                      />
                    </div>
                    <span className="errortext">{errors.org_domain_name}</span>
                  </FormGroup>
                </Col> */}
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Sub Domain *</Label>
                        <Input
                          // (Start)Remove Error msg by Clicking next tab validation for First Name Field

                          onBlur={(e) => handleInputBlur(e, "sub_domain")}
                          name="sub_domain"
                          onChange={handleInputChange}
                          value={formData && formData.sub_domain}
                          className={`form-control digits ${
                            formData && formData.sub_domain ? "" : ""
                          }`}
                          // onBlur={checkEmptyValue}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <span className="errortext">{errors.sub_domain}</span>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Website</Label>
                        <Input
                          // (Start)Remove Error msg by Clicking next tab validation for First Name Field

                          onBlur={(e) => handleInputBlur(e, "website")}
                          name="website"
                          onChange={handleInputChange}
                          value={formData && formData.website}
                          className={`form-control digits ${
                            formData && formData.website ? "" : ""
                          }`}
                          // onBlur={checkEmptyValue}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <span className="errortext">{errors.website}</span>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org PAN No *</Label>
                        <Input
                          // (Start)Remove Error msg by Clicking next tab validation for First Name Field

                          onBlur={(e) => handleInputBlur(e, "org_pan")}
                          name="org_pan"
                          onChange={handleInputChange}
                          value={formData && formData.org_pan}
                          className={`form-control digits ${
                            formData && formData.org_pan ? "" : ""
                          }`}
                          // onBlur={checkEmptyValue}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <span className="errortext">{errors.org_pan}</span>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">GSTIN No *</Label>
                        <Input
                          // draft
                          // (Start)Remove Error msg by Clicking next tab validation for Email Field

                          onBlur={(e) => handleInputBlur(e, "gstin")}
                          className={`form-control digits ${
                            formData && formData.gstin ? "" : ""
                          }`}
                          // className={`form-control digits ${formData && formData.email ? '' : ''}`}
                          value={formData && formData.gstin}
                          type="text"
                          name="gstin"
                          onChange={handleInputChange}
                        />
                      </div>
                      <span className="errortext">{errors.gstin}</span>
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">No Of Customers</Label>
                        <Input
                          // draft
                          // (Start)Remove Error msg by Clicking next tab validation for Email Field

                          onBlur={(e) => handleInputBlur(e, "customer_count")}
                          className={`form-control digits ${
                            formData && formData.customer_count ? "" : ""
                          }`}
                          // className={`form-control digits ${formData && formData.email ? '' : ''}`}
                          value={formData && formData.customer_count}
                          type="text"
                          name="customer_count"
                          onChange={handleInputChange}
                        />
                      </div>
                      <span className="errortext">{errors.customer_count}</span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Logo *</Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              alt="Selected"
                              style={{
                                maxWidth: "35%",
                                height: "35%",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                marginRight:"12px",
                                marginBottom:"3px"
                              }}
                            />
                          ) : (
                            <span>
                              <PersonLogoIcon />
                            </span>
                          )}

                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={UploadImageaadh}
                          />
                          <Button
                            color="primary"
                            type="button"
                            className="mr-3"
                            onClick={handleClick}
                          >
                            Add Logo
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                    <br/><br/>
                    <div
                className="password-notes"
                style={{ position: "relative", top: "-55px" }}
              >
                <div className="pass_notes">Notes :</div>
                <ul>          
                  <li>select a valid image file (JPEG, PNG, GIF)</li>
                  <li>Please select an image below 4MB</li>
                </ul>
                <span className="errortext">{errors.org_logo}</span>
              </div>
                  </Col>
                  {/* <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">
                          Org Identity Proof *
                        </Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {imagePreview1 ? (
                            <img
                              src={imagePreview1}
                              alt="Selected"
                              style={{
                                maxWidth: "35%",
                                height: "35%",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                              }}
                            />
                          ) : (
                            <span>
                              <PersonLogoIcon />
                            </span>
                          )}

                          <input
                            type="file"
                            ref={fileInputRef1}
                            style={{ display: "none" }}
                            onChange={handleFileChange1}
                          />
                          <Input
                            onBlur={(e) => handleInputBlur(e, "last_name")}
                            className={`form-control digits ${
                              formData && formData.last_name ? "" : ""
                            }`}
                            value={formData && formData.last_name}
                            type="text"
                            name="last_name"
                            onChange={handleInputChange}
                            placeholder="ID Number"
                            // onBlur={checkEmptyValue}
                            style={{
                              textTransform: "capitalize",
                              width: "70%",
                              margin: "5px",
                            }}
                          />
                          <Button
                            color="primary"
                            type="button"
                            className="mr-3"
                            onClick={handleClick1}
                          >
                            Add Identity Proof
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </Col> */}
                </Row>
                <div class="row">
                  <span
                    className="sidepanel_border"
                    style={{ position: "relative", top: "0px" }}
                  ></span>
                </div>
                <Row style={{ marginTop: "4%" }}>
                  <div
                    id="editmoveup"
                    style={{
                      textAlign: "left",
                      marginTop: "20px",
                      fontSize: "19px",
                      fontWeight: "600",
                      fontFamily: "Open Sans",
                      paddingLeft: "20px",
                      position: "relative",
                      top: "-25px",
                    }}
                  >
                    Personal Details
                  </div>
                </Row>
                <Row>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">First Name *</Label>
                        <Input
                          // (Start)Remove Error msg by Clicking next tab validation for First Name Field

                          onBlur={(e) => handleInputBlur(e, "first_name")}
                          name="first_name"
                          onChange={handleInputChange}
                          value={formData && formData.first_name}
                          className={`form-control digits ${
                            formData && formData.first_name ? "" : ""
                          }`}
                          // onBlur={checkEmptyValue}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <span className="errortext">{errors.first_name}</span>

                      {/*(End) Remove Error msg by Clicking next tab validation fired for First Name Field */}

                      {/*(End) Remove Error msg by Clicking next tab validation fired for First Name Field */}
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Last Name *</Label>
                        <Input
                          // draft
                          // (Start)Remove Error msg by Clicking next tab validation fired for Last Name Field
                          onBlur={(e) => handleInputBlur(e, "last_name")}
                          className={`form-control digits ${
                            formData && formData.last_name ? "" : ""
                          }`}
                          value={formData && formData.last_name}
                          type="text"
                          name="last_name"
                          onChange={handleInputChange}
                          // onBlur={checkEmptyValue}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                      <span className="errortext">{errors.last_name}</span>
                      {/*(End) Remove Error msg by Clicking next tab validation for Last Name Field */}
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Mobile Number *</Label>
                        <Input
                          // draft
                          // (Start)Remove Error msg by Clicking next tab validation fired for Mobile Number Field

                          onBlur={(e) => handleInputBlur(e, "mobile_no")}
                          className={`form-control digits ${
                            formData && formData.mobile_no ? "" : ""
                          }`}
                          value={formData && formData.mobile_no}
                          type="tel"
                          name="mobile_no"
                          onChange={handleInputChange}
                          //onBlur={checkEmptyValue} {/*Sailaja Commeted out this function for form_validation  & added handleInputBlur */}
                        />
                      </div>
                      <span className="errortext">{errors.mobile_no}</span>

                      {/*(End) Remove Error msg by Clicking next tab validation fo Mobile Number Field */}

                      {/*(End) Remove Error msg by Clicking next tab validation fo Mobile Number Field */}
                    </FormGroup>
                  </Col>
                  <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label
                          className="kyc_label"
                          style={{ whiteSpace: "nowrap", width: "110%" }}
                        >
                          Alternate Mobile Number
                        </Label>
                        <Input
                          // draft
                          /* Sailaja Added width Style id="moveup" on 13th July */
                          // Sailaja Adde onBlur Function for Alternate Mobile Number fiels on 13th March 2023                        style={{ width: "110%" }}
                          className={`form-control digits ${
                            formData && formData.alternate_mobile_no ? "" : ""
                          }`}
                          value={formData && formData.alternate_mobile_no}
                          type="tel"
                          name="alternate_mobile_no"
                          onChange={handleInputChange}
                          // onBlur={checkEmptyValue}
                          onBlur={(e) =>
                            handleInputBlur(e, "alternate_mobile_no")
                          }
                        />
                      </div>
                      <span className="errortext">
                        {errors.alternate_mobile_no}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col sm="3" id="moveup">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Email ID *</Label>
                        <Input
                          // draft
                          // (Start)Remove Error msg by Clicking next tab validation for Email Field

                          onBlur={(e) => handleInputBlur(e, "email")}
                          className={`form-control digits ${
                            formData && formData.email ? "" : ""
                          }`}
                          // className={`form-control digits ${formData && formData.email ? '' : ''}`}
                          value={formData && formData.email?.toLowerCase()}
                          type="email"
                          name="email"
                          onChange={handleInputChange}
                        />
                      </div>
                      <span className="errortext">{errors.email}</span>
                    </FormGroup>
                  </Col>
                </Row>

                <Row></Row>
                <div class="row">
                  <span
                    className="sidepanel_border"
                    style={{ position: "relative", top: "0px" }}
                  ></span>
                </div>
                <Row>
                  <Col id="moveup">
                    <div style={{ fontSize: "19px", fontWeight: "600" }}>
                      Configuration:
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <Label className="kyc_label">Email</Label>
                    <br />
                    <div
                      className={`franchise-switch ${emailToggle}`}
                      onClick={EmailToggle}
                    />
                  </Col>
                  <Col sm="3">
                    <Label className="kyc_label">SMS</Label>
                    <br />
                    <div
                      className={`franchise-switch ${smsToggle}`}
                      onClick={SMSToggle}
                    />
                  </Col>
                  <Col sm="3">
                    <Label className="kyc_label">Whatsapp</Label>
                    <br />
                    <div
                      className={`franchise-switch ${whatsappToggle}`}
                      onClick={WHATSAPPToggle}
                    />
                  </Col>
                  <Col sm="3">
                    <Label className="kyc_label">Maps</Label>
                    <br />
                    <div
                      className={`franchise-switch ${mapsToggle}`}
                      onClick={MapsToggle}
                    />
                  </Col>
                </Row>
                <br />
                <br />
                <div class="row">
                  <span
                    className="sidepanel_border"
                    style={{ position: "relative", top: "0px" }}
                  ></span>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginTop: "10px",
                    fontSize: "19px",
                    fontWeight: "600",
                    fontFamily: "Open Sans",
                    paddingLeft: "20px",
                    position: "relative",
                    top: "-4px",
                  }}
                >
                  Org Communication Address
                </div>
                <AddressComponent
                  handleInputChange={handleAddressInputChange}
                  checkEmptyValue={checkEmptyValue}
                  errors={errors}
                  setFormData={setFormData}
                  formData={formData.address}
                  setInputs={setInputs}
                  resetStatus={resetStatus}
                  setResetStatus={setResetStatus}
                  handleInputBlur={handleInputBlur}
                  // setIsDirtyFun={props.setIsDirtyFun}
                  showLatLng={false}
                />
                <div class="row">
                  <span
                    className="sidepanel_border"
                    style={{ position: "relative", top: "0px" }}
                  ></span>
                </div>
                <Col>
                  <span
                    id="editmoveup"
                    style={{
                      textAlign: "left",
                      marginTop: "20px",
                      fontSize: "19px",
                      fontWeight: "600",
                      fontFamily: "Open Sans",
                      paddingLeft: "10px",
                      position: "relative",
                      top: "-4px",
                      marginRight: "5px",
                    }}
                  >
                    Org Registered Address
                  </span>
                  {/* <Label className="kyc_label" style={{marginRight:"10px"}}>Registered Address</Label> */}
                  <div
                    className={`franchise-switch ${addressToggle}`}
                    onClick={AddressToggle}
                    style={{ marginRight: "5px" }}
                  />
                  <span>Same as Communication Address</span>
                  <br />
                </Col>

                {addressToggle === "off" ? (
                  <PermanentAddressComponent
                    handleInputChange={handlePermanentAddressInputChange}
                    checkEmptyValue={checkEmptyValue}
                    errors={errors}
                    setFormData={setFormData}
                    formData={formData.permanent_address}
                    setInputs={setInputs}
                    resetStatus={resetStatus}
                    setResetStatus={setResetStatus}
                    handleInputBlur={handleInputBlur}
                    // setIsDirtyFun={props.setIsDirtyFun}
                    showLatLng={false}
                  />
                ) : (
                  ""
                )}
                <Row>
                  <span
                    className="sidepanel_border"
                    style={{ position: "relative", top: "0px" }}
                  ></span>
                  {/* Sailaja Changed Create Button ID on 15th July Ref LED-29 */}
                  {/*Added spinner to create button in lead by Marieya on 28.8.22*/}
                  <Col>
                    <FormGroup className="mb-0" style={{ float: "left" }}>
                      <Button
                        name="autoclose2Toast"
                        color="btn btn-primary"
                        type="button"
                        className="mr-3"
                        onClick={submit}
                        id="create_button"
                        disabled={
                          loaderSpinneer ? loaderSpinneer : loaderSpinneer
                        }
                      >
                        {loaderSpinneer ? (
                          <Spinner size="sm" id="spinner">
                            {" "}
                          </Spinner>
                        ) : null}{" "}
                        &nbsp;
                        {"Create"}
                      </Button>
                      <Button
                        type="reset"
                        color="btn btn-secondary"
                        id="resetid"
                        onClick={() => resetformmanually()}
                      >
                        Reset
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <ErrorModal
            isOpen={showModal}
            toggle={() => setShowModal(false)}
            message={modalMessage}
            action={() => setShowModal(false)}
          />
          {/* . */}
        </Container>
      </div>
    </Fragment>
  );
};

export default withRouter(AddSuperAdmin);
