import React,{useEffect} from 'react';

import { connect } from 'react-redux'
import { getAllLeadsLoading, getAllOrgsLoading } from '../../../redux/leads/action'
import All from './all';

const SuperAdmin = (props)=>{
    useEffect(() => {   
        if (props?.leads?.length === 0) {
          props.getAllLeads()
        }
      }, [])
    return(
        <>
        <All allLeads={props.leads}  getAllLeads={props.getAllLeads}/>
        </>
    )
}


const mapStateToProps = (state) => { 
    return {
      leads: state.Leads.leads,
      loading: state.Leads.loading,
      error:state.Leads.error,
    }
  }
  
  const mapDispatchToProps = {
    getAllLeads: getAllOrgsLoading,
  }
  const SuperAdminContainer = connect(mapStateToProps, mapDispatchToProps)(SuperAdmin)
  export default SuperAdminContainer
  