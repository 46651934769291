import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Input,
  FormGroup,
  Spinner,
  Button,
} from "reactstrap";
import moment from "moment";
// import { toast } from "react-toastify";
import { default as axiosBaseURL, adminaxios, enhancementaxios } from "../../../axios";
import useFormValidation from "../../customhooks/FormValidation";
import {
  assignUser,
  frequency,
  leadStatus as leadStatusJson,
} from "./ConstatantData";
import AddressComponent from "./AddressComponent";
import EditIcon from "@mui/icons-material/Edit";
import { isEmpty } from "lodash";
import { LEAD } from "../../../utils/permissions";
import ErrorModal from "../../common/ErrorModal";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom/cjs/react-router-dom";
// Sailaja imported common component Sorting on 27th March 2023
import { Sorting } from "../../common/Sorting";
import AddressComponentRegEdit from "./AddressComponentRegEdit";
import { ReactComponent as PersonLogoIcon } from "../../../assets/images/person_logo_icon.svg";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
var storageToken = localStorage.getItem("token");

// let OrgData = JSON.parse(sessionStorage.getItem("orgDetails"));
// var tokenAccess = "";
if (storageToken !== null) {
  var token = JSON.parse(storageToken);
  // var tokenAccess = token?.access;
}
const tokenInfo = JSON.parse(localStorage.getItem("token"));
let isRootAdmin = false;
if (tokenInfo && tokenInfo.user_type === "Root Admin") {
  isRootAdmin = true;
}
const SuperAdminDetails = (props, initialValues) => {
  const [assignedTo, setAssignedTo] = useState([]);
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState(initialValues);
  const [Qualified, setQualified] = useState();
  const [assign, setAssign] = useState("");
  const [date, setDate] = useState();

  const [sourceby, setSourceby] = useState([]);
  const [typeby, setTypeby] = useState([]);
  const [selectdept, setSelectdept] = useState([]);
  const [leadUser, setLeadUser] = useState([]);
  const [selectZone, setSelectZone] = useState([]);
  const [isDisabled, setIsdisabled] = useState(false);
  const [resetStatus, setResetStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [addressToggle, setAddressToggle] = useState("on");
  const [isaddressShow, setAddressIsShow] = React.useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const history = useHistory();
  
  const selectStatus = [{id:"IN-Active",status:"IN"},{id:"Active",status:"ACT"}] ;
  //to disable button
  const [disable, setDisable] = useState(false);
  // sms toggle
  const [smsToggle, setSmsToggle] = useState("off");
  const [istelShow, setTelIsShow] = React.useState(false);
  function SMSToggle() {
    setSmsToggle(smsToggle === "off" ? "on" : "off");
    setTelIsShow(!istelShow);
  }
  const [smsToggle1, setSmsToggle1] = useState("on");
  const [istelShow1, setTelIsShow1] = React.useState(true);
  function SMSToggle1() {
    setSmsToggle1(smsToggle1 === "on" ? "off" : "on");
    setTelIsShow1(!istelShow1);
  }
  //Whatsapp toggle
  const [whatsappToggle, setWhatsappToggle] = useState("off");
  const [iswhatsShow, setWhatsIsShow] = React.useState(false);
  function WHATSAPPToggle() {
    setWhatsappToggle(whatsappToggle === "off" ? "on" : "off");
    setWhatsIsShow(!iswhatsShow);
  }
  const [whatsappToggle1, setWhatsappToggle1] = useState("on");
  const [iswhatsShow1, setWhatsIsShow1] = React.useState(true);
  function WHATSAPPToggle1() {
    setWhatsappToggle1(whatsappToggle1 === "on" ? "off" : "on");
    setWhatsIsShow1(!iswhatsShow1);
  }
  // email toggle
  const [emailToggle, setEmailToggle] = useState("off");
  const [isShow, setIsshow] = React.useState(false);
  function EmailToggle() {
    setEmailToggle(emailToggle === "off" ? "on" : "off");
    setIsshow(!isShow);
  }
  const [emailToggle1, setEmailToggle1] = useState("on");
  const [isShow1, setIsshow1] = React.useState(true);
  function EmailToggle1() {
    setEmailToggle1(emailToggle1 === "on" ? "off" : "on");
    setIsshow1(!isShow1);
  }
  //maps toggle
  const [mapsToggle, setMapsToggle] = useState("off");
  const [ismapsShow, setMapsIsShow] = React.useState(false);
  function MapsToggle() {
    setMapsToggle(mapsToggle === "off" ? "on" : "off");
    setMapsIsShow(!ismapsShow);
  }
  const [mapsToggle1, setMapsToggle1] = useState("on");
  const [ismapsShow1, setMapsIsShow1] = React.useState(true);
  function MapsToggle1() {
    setMapsToggle1(mapsToggle1 === "on" ? "off" : "on");
    setMapsIsShow1(!ismapsShow1);
  }
  // useEffect(() => {
  //   console.log(OrgData,"OrgData")
  //   if (OrgData) {
  //     setLeadUser(() => {
  //       return {
  //         ...OrgData,
  //       };
  //     });
  //   }
  // }, [OrgData]);
  useEffect(() => {
    // let orgData = JSON.parse(sessionStorage.getItem("orgDetails"));
    // orgData = { ...orgData, address: orgData.communication_address };
    // orgData = { ...orgData, permanent_address: orgData.registered_address };
    // orgData.first_name=orgData?.org_user?.first_name;
    // orgData.last_name=orgData?.org_user?.last_name;
    // orgData.register_mobile=orgData?.org_user?.register_mobile;
    // orgData.email=orgData?.org_user?.email;
    // orgData.alternate_mobile=orgData?.org_user?.alternate_mobile;
    // setLeadUser(orgData);
    createOrgData();
  }, []);

  const createOrgData=()=>{
    let orgData = JSON.parse(sessionStorage.getItem("orgDetails"));
    orgData = { ...orgData, address: orgData.communication_address };
    orgData = { ...orgData, permanent_address: orgData.registered_address };
    orgData.first_name=orgData?.org_user?.first_name;
    orgData.last_name=orgData?.org_user?.last_name;
    orgData.register_mobile=orgData?.org_user?.register_mobile;
    orgData.email=orgData?.org_user?.email;
    orgData.alternate_mobile=orgData?.org_user?.alternate_mobile;
    setLeadUser(orgData);
  }
  // useEffect(() => {
  //   leadUser.address=leadUser?.communication_address;
  //   leadUser.permanent_address=leadUser?.registered_address;
  //   setLeadUser(leadUser);
    
  // }, [leadUser]);

  const handleChange = (e) => {
    if (
      e.target.name == "street" ||
      e.target.name == "city" ||
      e.target.name == "landmark" ||
      e.target.name == "country" ||
      e.target.name == "pincode" ||
      e.target.name == "district" ||
      e.target.name == "state" ||
      e.target.name == "house_no"
    ) {
      let address = {
        ...leadUser.address,
        [e.target.name]: e.target.value,
      };
      setLeadUser({
        ...leadUser,
        address: address,
      });
    }
    //  else if (
    //   e.target.name == "first_name" ||
    //   e.target.name == "last_name" ||
    //   e.target.name == "register_mobile" ||
    //   e.target.name == "alternate_mobile" ||
    //   e.target.name == "email"
    // ) {
    //   let userDetails = {
    //     ...leadUser.org_user,
    //     [e.target.name]: e.target.value,
    //   };
    //   setLeadUser({
    //     ...leadUser,
    //     org_user: userDetails,
    //   });
    // } 
    // else if (
    //   e.target.name == "street1" ||
    //   e.target.name == "city1" ||
    //   e.target.name == "landmark1" ||
    //   e.target.name == "country1" ||
    //   e.target.name == "pincode1" ||
    //   e.target.name == "district1" ||
    //   e.target.name == "state1" ||
    //   e.target.name == "house_no1"
    // ) {
    //   let address1 = {
    //     ...leadUser.permanent_address,
    //     [e.target.name]: e.target.value,
    //   };
    //   setLeadUser({
    //     ...leadUser,
    //     permanent_address: address1,
    //   });
    // } 
    else {
      setLeadUser((prev) => ({
        ...prev,
        [e.target.name]:
          e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
      }));
    }
  };
  const handleChange1 = (e) => {
    if (
      e.target.name == "street" ||
      e.target.name == "city" ||
      e.target.name == "landmark" ||
      e.target.name == "country" ||
      e.target.name == "pincode" ||
      e.target.name == "district" ||
      e.target.name == "state" ||
      e.target.name == "house_no"
    ) {
      let address = {
        ...leadUser.permanent_address,
        [e.target.name]: e.target.value,
      };
      setLeadUser({
        ...leadUser,
        permanent_address: address,
      });
    
  };
}
  const leadDetails = (id) => {
    console.log("leadDetails is used");
    // setDisable(true)
    if (!isDisabled) {
      let submitData=leadUser;
      // setIsdisabled(true);
      submitData.email_api = leadUser?.email_api ? isShow1 : isShow;
      submitData.sms_api = leadUser?.sms_api ? istelShow1 : istelShow;
      submitData.whatsapp_api = leadUser?.whatsapp_api ? iswhatsShow1 : iswhatsShow;
      submitData.maps_api = leadUser?.maps_api ? ismapsShow1 : ismapsShow;
      submitData.documents.org_logo=leadUser?.documents?.org_logo;
      submitData.communication_address=leadUser?.address;
      submitData.registered_address=leadUser?.permanent_address;
      submitData.org_user.first_name=leadUser?.first_name;
      submitData.org_user.last_name=leadUser?.last_name;
      submitData.org_user.email=leadUser?.email;
      submitData.org_user.register_mobile=leadUser?.register_mobile;
      submitData.org_user.alternate_mobile=leadUser?.alternate_mobile;
     
      // delete submitData?.permanent_address;
      // delete submitData?.address;
      // delete submitData?.alternate_mobile;
      // delete submitData?.register_mobile;
      // delete submitData?.email;
      // delete submitData?.last_name;
      // delete submitData?.first_name;
      console.log("api is hiting", submitData);
      enhancementaxios
        .patch("/superadmin/org/update/" + id, submitData)
        .then((res) => {
         
          toast.success("Organization Updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          const redirectURL = `${process.env.PUBLIC_URL}/app/superAdmin/${process.env.REACT_APP_API_URL_Layout_Name}`;
          history.push(redirectURL);
        })
        .catch((e) => {   
          console.log(e,"error");
          if (e.response && e.response.data.org_code[0]) {
            toast.error(e.response && e.response.data.org_code[0], {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          // console.log(e, "error1");
          // // setModalMessage("Something went wrong");
          // toast.error("Something went wrong", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
        });
    }
  };

  // const clicked = (e) => {
  //   e.preventDefault();
  //   setIsdisabled(false);
  // };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  async function UploadImageaadh(e) {
    // Validate image size, type, height, and width
    const isValid = await validateImage(e.target);
    if (!isValid) {
      return; // Exit function if validation fails
    }

    // Continue with image upload process
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      let preview = await getBase64(e.target.files[0]);
    // setLeadUser((preState) => ({
    //   ...preState,
    //   org_logo: preview,
    //   logo_preview: imagePreview
    // }));
    setLeadUser((prevState) => ({
      ...prevState,
      documents: {
        ...prevState.documents,
        org_logo: preview,
        logo_preview: reader.result
      }
    }));
    }

    
   
  }

  async function validateImage(input) {
    const file = input.files[0];
    const fileSize = file.size / 1024 / 1024; // in MB

    // Check file size
    if (fileSize > 4) {
      toast.error("Please select an image below 4MB", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    }

    // Check file type
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Please select a valid image file (JPEG, PNG, GIF)", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    }

    // Optional: Check image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);
    await new Promise((resolve) => {
      img.onload = () => resolve();
    });

    // const maxWidth = 600; // Maximum allowed width
    // const maxHeight = 600; // Maximum allowed height
    // if (img.width > maxWidth || img.height > maxHeight) {
    //   toast.error("Image dimensions not supported", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   return false;
    // }

    return true; // Image passes all validations
  }
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  function checkEmptyValue(e) {
    if (e.target.value == "") {
      e.target.classList.remove("not-empty");
    } else {
      e.target.classList.add("not-empty");
    }
  }

  const requiredFields = [
    // "display_name",
    // "legal_name",
    
    // "sub_domain",
    // "website",
    // "gstin",
    // "org_email"
    "first_name",
    "last_name",
    "register_mobile",
    "email",
    "display_name",
    "legal_name",
    "org_code",
    "org_pan",
    "org_phone",
    // "org_logo",
    //  "org_domain_name",
    "sub_domain",
    "website",
    "gstin",
    "org_email",
    "house_no",
    "street",
    "city",
    "pincode",
    "district",
    "state",
    "country",
    "landmark",
  ];

  const { validate } = useFormValidation(requiredFields);

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e = e.target.name;

    const validationErrors = validate(leadUser);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    console.log("Validation Errors:", validationErrors); // Here's the console log for errors
    if (noErrors) {
      leadDetails(id);
    }
  };

  useEffect(() => {
    if (!props.rightSidebar) {
      setErrors({});
    }
  }, [props.rightSidebar]);

  useEffect(() => {
    if (props.openCustomizer) {
      setErrors({});
    }
  }, [props.openCustomizer]);

  function AddressToggle() {
    setAddressToggle(addressToggle === "on" ? "off" : "on");
    setAddressIsShow(!isaddressShow);
  }
  return (
    <Fragment>
      <Container fluid={true}>
        <Grid container spacing={1} id="breadcrumb_margin">
          <Grid item md="12">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <NavigateNextIcon fontSize="small" className="navigate_icon" />
              }
            >
              <Link
                to={`${process.env.PUBLIC_URL}/app/superAdmin/${process.env.REACT_APP_API_URL_Layout_Name}`}
              >
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color=" #377DF6"
                  fontSize="14px"
                >
                  Organizations
                </Typography>
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="#00000 !important"
                fontSize="14px"
                className="last_typography"
              >
                Edit Organizations
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <br />
        <br />
        <div className="edit-profile data_table">
          <Row>
            {/* {isRootAdmin && (
            <EditIcon
              className="icofont icofont-edit"
              style={{ top: "85px", right: "64px" }} id="org-edit"
              onClick={clicked}
              // disabled={isDisabled}
            />
          )} */}
          </Row>
          <Form>
            <Row style={{ marginTop: "4%" }}>
              <div
                id="editmoveup"
                style={{
                  textAlign: "left",
                  marginTop: "20px",
                  fontSize: "19px",
                  fontWeight: "600",
                  fontFamily: "Open Sans",
                  paddingLeft: "20px",
                  position: "relative",
                  top: "-25px",
                }}
              >
                Org Details
              </div>
            </Row>
            <Row style={{ marginTop: "12px" }}>
              {/* <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org ID *</Label>

                    <input
                    
                      className={`form-control ${
                        leadUser && leadUser.id ? "not-empty" : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="id"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.id}
                      onChange={handleChange}
                     
                      disabled={true}
                    ></input>
                  </div>
                  <span className="errortext">{errors.id}</span>
                </FormGroup>
              </Col> */}
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org Display Name *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.display_name
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="display_name"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.display_name}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      // disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.display_name}</span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org Legal Name *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.legal_name
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="legal_name"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.legal_name}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      //disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.legal_name}</span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org Code *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.org_code
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="org_code"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.org_code}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      //disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.org_code}</span>
                </FormGroup>
              </Col>
              <Col md="3">
              <div className="input_wrap">
              <Label className="kyc_label">Status *</Label>
                <select
                  className={`form-control digits not-empty`}
                  id="afterfocus"
                  type="select"
                  name="status"
                  style={{ border: "none", outline: "none" }}
                  value={leadUser && leadUser.status}
                  onChange={handleChange}
                  // onBlur={blur}
                  // disabled={isDisabled}
                >
                  {selectStatus.map((leadtype) => {
                    if (leadUser?.status) {
                      return (
                        <option
                         
                          value={leadtype.status}
                          selected={
                            leadtype.status == leadUser.status ? "selected" : ""
                          }
                        >
                          {leadtype.id}
                        </option>
                      );
                    }
                  })}
                </select>
             
              </div>
            </Col>
            <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org Phone No *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.org_phone
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="org_phone"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.org_phone}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      //disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.org_phone}</span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org Email Id *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.org_email
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="org_email"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.org_email}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      //disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.org_email}</span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org Sub Domain *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.sub_domain
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="sub_domain"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.sub_domain}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.sub_domain}</span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org Website *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.website ? "not-empty" : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="website"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.website}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.website}</span>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginTop: "12px" }}>
             
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Org PAN No *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.org_pan
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="org_pan"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.org_pan}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      //disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.org_pan}</span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">GSTIN *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.gstin ? "not-empty" : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="gstin"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.gstin}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.gstin}</span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">No Of Customers</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser.customer_count
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="customer_count"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser.customer_count}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">{errors.customer_count}</span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
            <Col sm="3">
                    <FormGroup>
                      <div className="input_wrap">
                        <Label className="kyc_label">Org Logo *</Label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {leadUser?.documents?.logo_preview ? (
                            <img
                              src={leadUser?.documents?.logo_preview}
                              alt="Selected"
                              style={{
                                maxWidth: "35%",
                                height: "35%",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                marginRight:"12px",
                                marginBottom:"3px"
                              }}
                            />
                          ) : (
                            <span>
                              <PersonLogoIcon />
                            </span>
                          )}

                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={UploadImageaadh}
                          />
                          <Button
                            color="primary"
                            type="button"
                            className="mr-3"
                            onClick={handleClick}
                          >
                            Re-Upload Logo
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                    <br/><br/>
                    <div
                className="password-notes"
                style={{ position: "relative", top: "-55px" }}
              >
                <div className="pass_notes">Notes :</div>
                <ul>          
                  <li>select a valid image file (JPEG, PNG, GIF)</li>
                  <li>Please select an image below 4MB</li>
                </ul>
              </div>
                  </Col>
            </Row>
            <div class="row">
              <span
                className="sidepanel_border"
                style={{ position: "relative", top: "0px" }}
              ></span>
            </div>
            <Row style={{ marginTop: "4%" }}>
              <div
                id="editmoveup"
                style={{
                  textAlign: "left",
                  marginTop: "20px",
                  fontSize: "19px",
                  fontWeight: "600",
                  fontFamily: "Open Sans",
                  paddingLeft: "20px",
                  position: "relative",
                  top: "-25px",
                }}
              >
                Personal Details
              </div>
            </Row>
            <Row>
                
                 
                </Row>
            <Row style={{ marginTop: "12px" }}>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">First Name *</Label>

                    <input
                      // className={`form-control digits not-empty`}
                      className={`form-control ${
                        leadUser && leadUser?.first_name
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="first_name"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser?.first_name}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      disabled={isDisabled}
                    ></input>
                  </div>
                  <span className="errortext">
                    {errors?.first_name}
                  </span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Last Name *</Label>

                    <input
                      // className="form-control digits not-empty"
                      className={`form-control ${
                        leadUser && leadUser?.last_name
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      type="text"
                      name="last_name"
                      style={{
                        border: "none",
                        outline: "none",
                        textTransform: "capitalize",
                      }}
                      value={leadUser && leadUser?.last_name}
                      onChange={handleChange}
                      // onBlur={checkEmptyValue}
                      disabled={isDisabled}
                    ></input>
                    {/* <Label className="kyc_label">Last Name *</Label> */}
                  </div>
                  <span className="errortext">
                    {errors?.last_name}
                  </span>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <div className="input_wrap">
                    <Label className="kyc_label">Mobile Number *</Label>

                    <input
                      // className="form-control digits not-empty"
                      className={`form-control ${
                        leadUser && leadUser?.register_mobile
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      id="afterfocus"
                      disabled={isDisabled}
                      type="text"
                      name="register_mobile"
                      style={{ border: "none" }}
                      value={leadUser && leadUser?.register_mobile}
                      onChange={handleChange}
                    ></input>
                  </div>
                  <span className="errortext">{errors.register_mobile}</span>
                </FormGroup>
              </Col>
              <Col md="3">
                {/* Sailaja gave style={{ whiteSpace: "nowrap" }} Alternate Mobile Number  on 11th July Ref LED-02 */}
                <FormGroup>
                  <div className="input_wrap">
                    <Label
                      className="kyc_label"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {" "}
                      Alternate Mobile Number{" "}
                    </Label>

                    <input
                      // className="form-control digits not-empty"
                      className={`form-control ${
                        leadUser && leadUser?.alternate_mobile
                          ? "not-empty"
                          : "not-empty"
                      }`}
                      type="text"
                      name="alternate_mobile"
                      // Sailaja Added width Style (Line Number 356)on 13th July */}
                      style={{ border: "none", outline: "none", width: "110%" }}
                      value={leadUser && leadUser?.alternate_mobile}
                      onChange={handleChange}
                      id="afterfocus"
                      disabled={isDisabled}
                    ></input>
                  </div>
                  {/* <span className="errortext">{errors.alternate_mobile_no}</span> */}
                </FormGroup>
              </Col>

              <Col md="3" id="moveup">
                <div className="input_wrap">
                  <Label className="kyc_label"> Email ID * </Label>

                  <input
                    // className="form-control digits not-empty"
                    className={`form-control ${
                      leadUser && leadUser?.email
                        ? "not-empty"
                        : "not-empty"
                    }`}
                    type="email"
                    name="email"
                    style={{ border: "none", outline: "none" }}
                    value={leadUser && leadUser?.email?.toLowerCase()}
                    onChange={handleChange}
                    id="afterfocus"
                    disabled={isDisabled}
                  ></input>
                </div>
                <span className="errortext">{errors?.email}</span>
              </Col>
            </Row>
            <div class="row">
              <span
                className="sidepanel_border"
                style={{ position: "relative", top: "0px" }}
              ></span>
            </div>
            <br/>
            <Row>
              <Col id="moveup">
                <div style={{ fontSize: "19px", fontWeight: "600" }}>
                  Configuration:
                </div>
              </Col>
            </Row>
            <br/><br/>
            <Row>
              {leadUser?.email_api === true ? (
                <Col sm="3">
                  <Label className="kyc_label">Email</Label>
                  <br />
                  <div
                    className={`franchise-switch ${emailToggle1}`}
                    onClick={EmailToggle1}
                  />
                </Col>
              ) : (
                <Col sm="3">
                  <Label className="kyc_label">Email</Label>
                  <br />
                  <div
                    className={`franchise-switch ${emailToggle}`}
                    onClick={EmailToggle}
                  />
                </Col>
              )}
              {/* sms */}
              {leadUser?.sms_api === true ? (
                <Col sm="3">
                  <Label className="kyc_label">SMS</Label>
                  <br />
                  <div
                    className={`franchise-switch ${smsToggle1}`}
                    onClick={SMSToggle1}
                  />
                </Col>
              ) : (
                <Col sm="3">
                  <Label className="kyc_label">SMS</Label>
                  <br />
                  <div
                    className={`franchise-switch ${smsToggle}`}
                    onClick={SMSToggle}
                  />
                </Col>
              )}
              {leadUser?.whatsapp_api === true ? (
                <Col sm="3">
                  <Label className="kyc_label">Whatsapp</Label>
                  <br />
                  <div
                    className={`franchise-switch ${whatsappToggle1}`}
                    onClick={WHATSAPPToggle1}
                  />
                </Col>
              ) : (
                <Col sm="3">
                  <Label className="kyc_label">Whatsapp</Label>
                  <br />
                  <div
                    className={`franchise-switch ${whatsappToggle}`}
                    onClick={WHATSAPPToggle}
                  />
                </Col>
              )}
              {leadUser?.maps_api === true ? (
                <Col sm="3">
                  <Label className="kyc_label">Maps</Label>
                  <br />
                  <div
                    className={`franchise-switch ${mapsToggle1}`}
                    onClick={MapsToggle1}
                  />
                </Col>
              ) : (
                <Col sm="3">
                  <Label className="kyc_label">Maps</Label>
                  <br />
                  <div
                    className={`franchise-switch ${mapsToggle}`}
                    onClick={MapsToggle}
                  />
                </Col>
              )}
            </Row>
            <br />
            <br />

            <div class="row">
              <span
                className="sidepanel_border"
                style={{ position: "relative", top: "0px" }}
              ></span>
            </div>
            <Row style={{ marginTop: "4%" }}>
              <div
                id="editmoveup"
                style={{
                  textAlign: "left",
                  marginTop: "20px",
                  fontSize: "19px",
                  fontWeight: "600",
                  fontFamily: "Open Sans",
                  paddingLeft: "20px",
                  position: "relative",
                  top: "-25px",
                }}
              >
                Org Communication Address
              </div>
            </Row>
            <br />
            <AddressComponent
              handleInputChange={handleChange}
              errors={errors}
              setFormData={setLeadUser}
              formData={leadUser.address}
              setInputs={setInputs}
              resetStatus={resetStatus}
              setResetStatus={setResetStatus}
              isDisabled={isDisabled}
            />
            <div class="row">
              <span
                className="sidepanel_border"
                style={{ position: "relative", top: "0px" }}
              ></span>
            </div>
            <Col>
              <span
                id="editmoveup"
                style={{
                  textAlign: "left",
                  marginTop: "20px",
                  fontSize: "19px",
                  fontWeight: "600",
                  fontFamily: "Open Sans",
                  paddingLeft: "10px",
                  position: "relative",
                  top: "-4px",
                  marginRight: "5px",
                }}
              >
                Org Registered Address
              </span>
              {/* <Label className="kyc_label" style={{marginRight:"10px"}}>Registered Address</Label> */}
              {/* <div
                className={`franchise-switch ${addressToggle}`}
                onClick={AddressToggle}
                style={{ marginRight: "5px" }}
              />
              <span>Same as Communication Address</span>
              <br /> */}
            </Col>
            <br />
            <AddressComponentRegEdit
              handleInputChange1={handleChange1}
              errors={errors}
              setFormData={setLeadUser}
              formData={leadUser.permanent_address}
              setInputs={setInputs}
              resetStatus={resetStatus}
              setResetStatus={setResetStatus}
              isDisabled={isDisabled}
            />
             <br />
             <br />
            <div style={{ marginTop: "-77px" }}>
              <Row>
                <span
                  className="sidepanel_border"
                  style={{ position: "relative", top: "23px" }}
                ></span>
              </Row>
              <br />
              <button
                type="button"
                name="submit"
                className="btn btn-primary"
                id="save_button"
                onClick={(e) => {
                  handleSubmit(e, leadUser?.id);
                }}
                disabled={isDisabled}
              >
                {disable ? <Spinner size="sm"> </Spinner> : null}
                Save
              </button>
              &nbsp; &nbsp;
              <Link
                to={`${process.env.PUBLIC_URL}/app/superAdmin/${process.env.REACT_APP_API_URL_Layout_Name}`}
              >
                <button
                  type="button"
                  name="submit"
                  className="btn btn-secondary"
                  id="resetid"
                  // onClick={props.dataClose}
                >
                  Cancel
                </button>
              </Link>
            </div>
          </Form>
        </div>
        <ErrorModal
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          message={modalMessage}
          action={() => setShowModal(false)}
        />
      </Container>
    </Fragment>
  );
};

export default SuperAdminDetails;
