import axios from "axios";

var storageToken = localStorage.getItem("token");
var backupStorageToken = localStorage.getItem("backup");
 if (storageToken === null) {
var tokenAccess = ''
}
else{
  var token = JSON && JSON?.parse(storageToken) ;
  var tokenAccess = token && token.access;
}
//for franchise switch when logged in is franchise we need to use admin token where it is in backup
if (backupStorageToken === null) {
  var backuptokenAccess = ''
  }
  else{
    var backuptoken = JSON && JSON.parse(backupStorageToken) ;
    var backuptokenAccess = backuptoken && backuptoken.access;
  }
//ends

const currentSubdomain = window.location.hostname;

console.log(currentSubdomain,"currentSubdomain")
// admin
const adminURL = process.env.REACT_APP_API_URL.replace('enhancement.sparkradius.in', currentSubdomain)
console.log(adminURL,"adminURL")

export default axios.create({
  baseURL: adminURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
});



// franchise
const franchiseURL = process.env.REACT_APP_API_URL_FRANCHISE.replace('enhancement.sparkradius.in', currentSubdomain);

export const franchiseaxios = axios.create({
  baseURL : franchiseURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}

});


// helpdesk

const helpdeskURL = process.env.REACT_APP_API_URL_HELPDESK.replace('enhancement.sparkradius.in', currentSubdomain)

export const helpdeskaxios = axios.create({
  baseURL: helpdeskURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}

});


// adminaxios
const adminaxiosURL = process.env.REACT_APP_API_URL_ADMIN.replace('enhancement.sparkradius.in', currentSubdomain)

console.log(adminaxiosURL,"adminaxiosURL")
export const adminaxios = axios.create({
  baseURL: adminaxiosURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
});




// adminaxiosWithoutToken
const adminaxiosWithoutTokenURL = process.env.REACT_APP_API_URL_ADMIN.replace('enhancement.sparkradius.in', currentSubdomain)

export const adminaxiosWithoutToken = axios.create({
  baseURL: adminaxiosWithoutTokenURL
});

// service URL
const servicesURL = process.env.REACT_APP_API_URL_SERVICES.replace('enhancement.sparkradius.in', currentSubdomain)

export const servicesaxios = axios.create({
  baseURL: servicesURL,  
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
});


// Network URL
const networkURL = process.env.REACT_APP_API_URL_NETWORK.replace('enhancement.sparkradius.in', currentSubdomain)

export const networkaxios = axios.create({
  baseURL: networkURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
  
});


// customer URL

const customerURL = process.env.REACT_APP_API_URL_CUSTOMER.replace('enhancement.sparkradius.in', currentSubdomain)

export const customeraxios = axios.create({
  baseURL: customerURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
});



// customeraxiosform url

const customeraxiosFormURL = process.env.REACT_APP_API_URL_CUSTOMER.replace('enhancement.sparkradius.in', currentSubdomain)

export const customeraxiosForm = axios.create({
  baseURL: customeraxiosFormURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`, 'content-type': 'application/json'}
});



// billing url
const billingURL = process.env.REACT_APP_API_URL_BILLING.replace('enhancement.sparkradius.in', currentSubdomain)

export const billingaxios = axios.create({
  baseURL: billingURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
});


// staff url 
const staffURL = process.env.REACT_APP_API_URL_STAFF.replace('enhancement.sparkradius.in', currentSubdomain)

export const staffaxios = axios.create({
  baseURL: staffURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
  
});


// campign url
const campignURL = process.env.REACT_APP_API_URL_CAMPAIGN.replace('enhancement.sparkradius.in', currentSubdomain)

export const campaignaxios = axios.create({
  baseURL: campignURL,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
});

export const iptvaxios = axios.create({
  baseURL: 'http://125.62.213.82:9081/admin/',
  headers: {"Authorization": `Bearer ${localStorage.getItem('iptvtoken')}`}
});

//for franchise switch when logged in is franchise we need to use admin token where it is in backup


const adminaxiosFranchiseSwitchURL = process.env.REACT_APP_API_URL_ADMIN.replace('enhancement.sparkradius.in', currentSubdomain)

export const adminaxiosFranchiseSwitch = axios.create({
  baseURL: adminaxiosFranchiseSwitchURL,
  headers: {"Authorization" : `Bearer ${backuptokenAccess}`}
});



const franchiseaxiosSwitchURL = process.env.REACT_APP_API_URL_FRANCHISE.replace('enhancement.sparkradius.in', currentSubdomain)

export const franchiseaxiosSwitch = axios.create({
  baseURL: franchiseaxiosSwitchURL,
  headers: {"Authorization" : `Bearer ${backuptokenAccess}`}

});
export const enhancementaxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL_ENHANCEMENT,
  headers: {"Authorization" : `Bearer ${tokenAccess}`}
});
// export const webSocket = axios.create({
//   baseURL: process.env.REACT_APP_WS_URL,
//   headers: {"Authorization" : `Bearer ${tokenAccess}`}
// });