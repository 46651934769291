import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchAllLeads, fetchAllOrgs } from '../../api'
import {
  GET_ALL_LEADS_LOADING, GET_ALL_ORGS_ERROR, GET_ALL_ORGS_LOADING,
} from '../actionTypes'
import {
  getAllLeadsError,
  getAllLeadsSuccess,
  getAllOrgsError,
  getAllOrgsSuccess,
} from './action'

function* fetchLeadsAsyn() {
  try {
    //yield put(getAllLeadsLoading())
    const leads = yield call(fetchAllLeads) //API call
    yield put(getAllLeadsSuccess(leads.data))
  } catch (error) {
    yield put(getAllLeadsError(error))
  }
}

export function* watcherLeadsApp() {
  yield takeLatest(  GET_ALL_LEADS_LOADING , fetchLeadsAsyn)
}

function* fetchOrgsAsync() {
  try {
    //yield put(getAllLeadsLoading())
    const Orgs = yield call(fetchAllOrgs) //API call
    console.log(Orgs.data,"Orgs")
    yield put(getAllOrgsSuccess(Orgs.data))
  } catch (error) {
    yield put(GET_ALL_ORGS_ERROR(error))
  }
}

export function* watcherOrgsApp() {
  yield takeLatest(  GET_ALL_ORGS_LOADING , fetchOrgsAsync)
}